export const RUSSIAN_TRANSLATIONS = {
  popsUnprovided: 'Не указан виджет в настройках',
  emptyList: 'Список пуст',
  emptyContent: 'Пусто',
  eventStatuses: {
    ViolationDetected: 'Тревога',
    Handled: 'Угрозы нет',
    FalsePositive: 'Ложное',
  },
}
