const timers = {} as any
export const debounce = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  callback: Function,
  timeout: number,
  key = 'main',
): void => {
  clearTimeout(timers[key])
  timers[key] = setTimeout(callback, timeout)
}
